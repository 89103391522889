body {
  font-family: "Open Sans", sans-serif;
  color: #5a5a5a;
  background-image: url("../public/images/flip-flop-pattern.svg");
  background-size: 20rem;
  background-position: center;
}

h1,
h2,
h3,
.card-body > .card-title {
  font-family: "Amatic SC", cursive;
  font-weight: bold;
}

h1 {
  font-family: "Permanent Marker", cursive;
  font-size: 4rem;
  letter-spacing: 0.3rem;
}

h2 {
  font-size: 3rem;
  line-height: 3.5rem;
}

header {
  padding-top: 0.375rem;
}

header::before {
  content: "";
  height: 0.375rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(to right, #fe6625 0%, #fe6625 25%, #414a4f 25%, #414a4f 50%, #fb9334 50%, #fb9334 75%, #003d59 75%, #003d59 100%);
}

@media only screen and (min-width: 768px) {
  header::before {
    background: linear-gradient(
      to right,
      #fe6625 0%,
      #fe6625 12.5%,
      #414a4f 12.5%,
      #414a4f 25%,
      #fb9334 25%,
      #fb9334 37.5%,
      #003d59 37.5%,
      #003d59 50%,
      #fe6625 50%,
      #fe6625 62.5%,
      #414a4f 62.5%,
      #414a4f 75%,
      #fb9334 75%,
      #fb9334 87.5%,
      #003d59 87.5%,
      #003d59 100%
    );
  }
}

.home-page-container header {
  background-image: url("../public/images/flip-flop-cover.svg");
  background-repeat: no-repeat;
  background-position: top -50px left 50%;
  background-size: 1000px;
  min-height: 24rem;
}

@media only screen and (max-width: 768px) {
  .home-page-container .page-section div.container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.page-heading-section h1 {
  color: #fb9334;
}

.page-heading-section p {
  font-weight: bold;
  max-width: 50rem;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .home-page-container header {
    background-position: top -180px left 50%;
    background-size: 1300px;
  }
}

@media only screen and (min-width: 1200px) {
  .home-page-container header {
    background-position: top -275px left 50%;
    background-size: 1550px;
  }
}

@media only screen and (max-width: 768px) {
  nav.navbar div.navbar-collapse {
    background-color: rgb(22, 112, 112);
    text-align: center;
    z-index: 100;
    position: absolute;
    top: 4.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    left: 0%;
  }
}

nav.navbar-dark {
  /* internal page nav */
  background-color: #167070 !important;
}

.home-page-container nav.navbar-dark {
  background-color: transparent !important;
}

.dropdown-menu .dropdown-item .nav-link,
.dropdown-menu .dropdown-item .nav-link.active {
  color: #04bf9d;
}

.navbar-brand span {
  font-family: "Permanent Marker", cursive;
  color: #ffffff;
}

.card-body > .card-title {
  font-size: 2.5rem;
}

.nav-link {
  cursor: pointer;
}

.card {
  background-color: transparent;
}

.card-img-overlay .card-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.row-content {
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 200px;
}

.card-img {
  height: 12rem;
}

.blockquote-footer {
  margin-top: 1rem;
}

.lost-links-list ul {
  list-style: none;
}

.lost-links-list ul li h2::before {
  content: "👍";
  display: inline-block;
  margin-right: 1rem;
}

footer {
  background-color: #003d59;
}

footer section:first-of-type {
  background-color: #fb9334;
  color: #003d59;
}

footer section:first-of-type i {
  color: #003d59;
}

footer section:nth-of-type(2n) hr {
  width: 60px;
  background-color: #fe6625;
  height: 4px;
}

footer > div {
  background-color: rgba(0, 0, 0, 0.2);
}
